import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "floating" }
const _hoisted_2 = {
  key: 0,
  class: "splashText"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_blog_ticker = _resolveComponent("blog-ticker")!
  const _component_map_menu = _resolveComponent("map-menu")!
  const _component_pages_menu = _resolveComponent("pages-menu")!
  const _component_DashboardWidget = _resolveComponent("DashboardWidget")!
  const _component_world_map = _resolveComponent("world-map")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_ctx.showTitle)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  (_ctx.isMobile)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        innerHTML: _ctx.$t('title.splashTextMobile')
                      }, null, 8, _hoisted_3))
                    : (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        innerHTML: _ctx.$t('title.splashText')
                      }, null, 8, _hoisted_4))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_router_view),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_blog_ticker, null, null, 512), [
            [_vShow, _ctx.showTitleDone]
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_map_menu, null, null, 512), [
            [_vShow, _ctx.showTitleDone]
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_pages_menu, null, null, 512), [
            [_vShow, _ctx.showTitleDone]
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_DashboardWidget, null, null, 512), [
            [_vShow, _ctx.showTitleDone]
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_world_map, { showMapComponents: _ctx.showTitleDone }, null, 8, ["showMapComponents"])
  ], 64))
}