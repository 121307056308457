
import { store } from "./../data/store";
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/continentsLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { defineComponent } from "vue";
import { Post } from "./../types";
import sexagesimal from "@mapbox/sexagesimal";
//----[ Trade routes ]----------
import routes from "../data/routes"

//----[ Map root element ]----------
let root = undefined;

//const RESET_TO_HOME_TIME_IN_SECONDS = 0.8;
const CARGOWATCH_LOCATION = {
  latitude: 52.180175,
  longitude: 5.050515, 
  formattedCoords: formatCoords(52.180175, 5.050515),
};
const GLOBAL_CENTER_POINT = {
  latitude: 30.222196,
  longitude: 5.050515,
  formattedCoords: formatCoords(20.222196, 5.050515),
};

const GLOBAL_MOBILE_CENTER_POINT_PORTRAIT = {
  latitude: 33.5, 
  longitude: 20.640001,
} as {longitude: number, latitude: number};

const GLOBAL_MOBILE_CENTER_POINT_LANDSCAPE = {
  latitude: 48.785644, 
  longitude: -13.486311,
} as {longitude: number, latitude: number};

function formatCoords (latitude: number, longitude: number): string {
  const lat = sexagesimal.coordToDMS(latitude, "lat");
  const lon = sexagesimal.coordToDMS(longitude, "lon");
  return `${latitude.toFixed(4)}° ${lat.dir}<br />${longitude.toFixed(4)}° ${lon.dir}`;
}

export default defineComponent({
  props: {
    showMapComponents: {
      type: Boolean,
      default: false,
    },
    latitude: {
      type: Number,
      default: 33.5
    },
    longitude: {
      type: Number,
      default: 20.640001
    },
    zoom: {
      type: Number,
      default: 6

    }
  },
  inject: ['emitter'],
  computed: {
    GLOBAL_MOBILE_CENTER_POINT (): { longitude: number, latitude: number } {

      GLOBAL_MOBILE_CENTER_POINT_LANDSCAPE.latitude = this.latitude
      GLOBAL_MOBILE_CENTER_POINT_LANDSCAPE.longitude = this.longitude

      GLOBAL_MOBILE_CENTER_POINT_PORTRAIT.latitude = this.latitude
      GLOBAL_MOBILE_CENTER_POINT_PORTRAIT.longitude = this.longitude

      if (this.mobileOrientation.includes('landscape')) {
        return GLOBAL_MOBILE_CENTER_POINT_LANDSCAPE
      } else {
        return GLOBAL_MOBILE_CENTER_POINT_PORTRAIT
      }
    }
  },
  data () {
    return {
      store,
      chart: undefined as unknown as am5map.MapChart,
      polygonSeries: undefined as unknown as am5map.MapPolygonSeries,
      backgroundSeries: undefined as unknown as am5map.MapPolygonSeries,
      lineSeries: undefined as unknown as am5map.MapLineSeries,
      pointSeries: undefined as unknown as am5map.MapPointSeries,
      boatSeries: undefined as unknown as am5map.MapPointSeries,
      postLabel: undefined as unknown as am5.Label,
      postPoint: undefined as unknown as am5map.MapPointSeries,
      logo: undefined as unknown as am5map.MapPointSeries,
      logoLabel: undefined as unknown as am5.Label,
      mobileOrientation: 'unknown'
    };
  },
  methods: {
    clearRouteAndPoiMark(): void {
      if (this.lineSeries !== undefined) {
        this.lineSeries.data.clear();
      }

      if (this.pointSeries !== undefined) {
        this.pointSeries.data.clear()
      }
    },
    mapZoomIn (): void {
      this.chart.zoomIn();
    },
    mapZoomOut (): void {
      this.chart.zoomOut();
    },
    zoomToPoi (post: Post): void {

      // First reset
      const coords = {
        longitude: parseFloat(post.attributes.longitude),
        latitude: parseFloat(post.attributes.latitude),
        formattedCoords: formatCoords(
          parseFloat(post.attributes.latitude),
          parseFloat(post.attributes.longitude)
        ),
      };

      if (this.pointSeries !== undefined) {
        this.pointSeries.data.clear()
      }

      // Create point series
      this.pointSeries = this.chart.series.push(
        am5map.MapPointSeries.new(root, {
          layer: 100
        })
      );

      //create destination point
      let that = this
      this.pointSeries.bullets.push(function (root) {
        var container = am5.Container.new(root, {});

        let postLabel = am5.Label.new(root, {
          populateText: true,
          fontSize: '11pt',
          html: "<strong class=\"toolTipText\">" + coords.formattedCoords + "</strong>",
          fontFamily: "Avenir Next Condensed Medium, sans-serif",
          x: 10,
          y: 5
        })
        that.postLabel = postLabel

        container.children.push(postLabel);
        let postPoint =  am5.Circle.new(root, {
          radius: 4,
          fill: am5.color(0xd3d800),
          stroke: am5.color(0xd3d800)
        })
        container.children.push(postPoint)
        that.postPoint = postPoint

        return am5.Bullet.new(root, {
          sprite: container
        });
      });

      this.pointSeries.pushDataItem(coords);
      
      //----[ animate line ]----------
      if (this.lineSeries === undefined) {
        this.lineSeries = this.chart.series.push(
          am5map.MapLineSeries.new(root, {})
        );
      }

      let strokeDashoffset = post.attributes.strokeDashoffset
      let strokeDasharray = post.attributes.strokeDasharray
      let animationTimeDuration = post.attributes.ReisTijd * 1000

      this.lineSeries.mapLines.template.setAll({
        stroke: am5.color(0xD9DBD0),
        strokeWidth: 1,
        strokeDashoffset: strokeDashoffset,
        strokeDasharray: strokeDasharray
      });

      this.lineSeries.data.setAll([{
        "geometry": {
          "type": "LineString",
          "coordinates": [
            [CARGOWATCH_LOCATION.longitude, CARGOWATCH_LOCATION.latitude],
            [coords.longitude, coords.latitude]
          ]
        }
      }]);

      let lastDataItem = this.lineSeries.dataItems[this.lineSeries.dataItems.length - 1];
      var lastMapLine = lastDataItem.get("mapLine");

      lastMapLine.animate({
        key: "strokeDashoffset",
        to: 0,
        duration: animationTimeDuration,
        easing: am5.ease.linear
      });

      // Only zoom to position when not on mobile
      if (!this.$isMobile) {
        
        window.setTimeout(function () {
          that.store.showPageOverlay = true;
        }, post.attributes.AnimatietijdReis * 1000);

        this.chart.zoomToGeoPoint(
          { latitude: post.attributes.positionLatitude, longitude: post.attributes.positionLongitude }, // Zoom to post specific center location
          post.attributes.Zoomlevel, // Zoom to post specific zoom level
          true,
          0.8 * 1000
        );

      } else {
        that.store.showPageOverlay = true;
      }
    },
    addLogo (): void {

      //----[ LOGO -> Todo make hidden on start ]----------
      this.logo = this.chart.series.push(
        am5map.MapPointSeries.new(root, {
          polygonIdField: "logo"
        })
      );

      this.logo.data.setAll([{
        geometry: {
          type: "Point",
          coordinates: [CARGOWATCH_LOCATION.longitude, CARGOWATCH_LOCATION.latitude]
        }
      }])

      let that = this
      this.logo.bullets.push(function(root) {
        var container = am5.Container.new(root, {});

        let picture = container.children.push(am5.Picture.new(root, {
          src: "/img/cargowatch-logo.png",
          layer: 5,
          dx: -94,
          dy: -88,
          visible: false,
        }))
        that.logo = picture

        picture.events.on("click", function () {
          that.emitter.emit('closeOverlay')
        })

        let logoLabel = am5.Label.new(root, {
          fontSize: '11pt',
          html: "<strong class=\"toolTipText\">" + CARGOWATCH_LOCATION.formattedCoords + "<strong>",
          fontFamily: "Avenir Next Condensed Medium, sans-serif",
          x: 0,
          y: -10,
          visible: false,
        })
        container.children.push(logoLabel);
        that.logoLabel = logoLabel

        return am5.Bullet.new(root, {
          sprite: container
        });
      })
    },
    addRoutes (): void {
      //----[ Create line series ]----------
      let lineSeries = this.chart.series.push(
        am5map.MapLineSeries.new(root, {})
      );

      lineSeries.mapLines.template.setAll({
        visible: false, //change this to "true" to see the actual route
      });

      // Create point series
      let pointSeries = this.chart.series.push(
        am5map.MapPointSeries.new(root, {})
      );

      //create boat
      pointSeries.bullets.push(function () {
        let circle = am5.Circle.new(root, {
          radius: 1,
          fill: am5.color(0xcbdb2f),
          stroke: am5.color(0xcbdb2f),
          visible: false,
        });

        return am5.Bullet.new(root, {
          sprite: circle
        });
      });
      pointSeries.bulletsContainer.toBack()
      this.boatSeries = pointSeries;

      let k: keyof typeof routes;
      let routeId = 0;
      let vaarRoutes = [];

      for (k in routes) {
        const v = routes[k];  // OK
        vaarRoutes[routeId] = lineSeries.pushDataItem(v);
        routeId++;
      }

      for (let index = 0; index < vaarRoutes.length; index++) {
        const element = vaarRoutes[index];
        let numberOfBoats = randomIntFromInterval(5, 15)
        for (let boat = 0; boat < numberOfBoats; boat++) {
          addBoatToLine(element);
        }
      }

      function randomIntFromInterval (min, max): number { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
      }

      function getRandomBoolean() {
        return Math.random() < 0.5;
      }

      function addBoatToLine (route) {
        let boat = pointSeries.pushDataItem({
          lineDataItem: route,
        });

        let boatStart = randomIntFromInterval(0, 3) / 10
        let boatStop = randomIntFromInterval(7, 10) / 10
        
        let BoatSailingDirection = [boatStart, boatStop]

        if(getRandomBoolean()) {
          BoatSailingDirection.reverse()
        }

        boat.animate({
          key: "positionOnLine",
          from: BoatSailingDirection[0],
          to: BoatSailingDirection[1],
          duration: 120000,
          loops: Infinity,
          easing: am5.ease.inOut(am5.ease.linear)
        });
      }
    },
    createMap (): void {
      // Create root and chart
      root = am5.Root.new("mapsdiv");
      root.setThemes([
        am5themes_Animated.new(root)
      ]);
      
      let homeZoomLevel = this.$isMobile ? this.zoom : 2
      
      this.chart = root.container.children.push(
        am5map.MapChart.new(root, {
          panX: "none",
          panY: "none",
          projection: am5map.geoNaturalEarth1(),
          wheelY: "none",
          minZoomLevel: 1,
          wheelSensitivity: 0.05,
          maxZoomLevel: 16,
          paddingTop: 60,
          homeZoomLevel: homeZoomLevel
        })
      );

      if(!this.$isMobile) {
        this.chart.set('paddingRight', 20)
        this.chart.set('zoomLevel', 1)
        this.chart.set('homeGeoPoint', GLOBAL_CENTER_POINT)
      } else {
        this.chart.set('paddingLeft', 150)
        this.chart.set('zoomLevel', 3)
        this.chart.set('homeGeoPoint', this.GLOBAL_MOBILE_CENTER_POINT)
      }

      // Create polygon series
      this.polygonSeries = this.chart.series.push(
        am5map.MapPolygonSeries.new(root, {
          geoJSON: am5geodata_worldLow,
          exclude: ["antarctica"],
          fill: am5.color(0xffffff),
          stroke: am5.color(0xffffff)
        })
      );

      this.polygonSeries.mapPolygons.template.setAll({
        shadowColor: am5.color(0xe6e5df),
        shadowBlur: 20,
        shadowOffsetX: 30,
        shadowOffsetY: 20,
      })

      this.polygonSeries.events.on("datavalidated", () => {
        this.chart.goHome();
      });

      // Make stuff animate on load
      this.chart.appear(3000, 100);

      this.addLogo()
      this.addRoutes()
    }
  },
  created() {
    window.addEventListener(
      "deviceorientation",
      () => {
        const orientation = window.screen.orientation.type
        if (orientation === "portrait-primary") {
          this.mobileOrientation = 'portrait'
        } else if (orientation === "landscape-primary") {
          this.mobileOrientation = 'landscape'
        }
      }
    );
  },
  mounted () {
    this.createMap()

    this.emitter.on("showLogo", (show: boolean) => { 
      this.logo.set("visible", show)
    })

    this.emitter.on("showLogoLabel", (show: boolean) => { 
      if (this.logoLabel) {
        this.logoLabel.set("visible", show)
      }
    })

    this.emitter.on("showBoats", (show) => {
      this.boatSeries.bulletsContainer.eachChildren((boats) => {
        boats.set("visible", show)
      })
    })

    this.emitter.on("clearRoute", () => {
      this.clearRouteAndPoiMark()
    })

    this.emitter.on("goHome", () => {
      this.chart.goHome()
    });

    this.emitter.on("postClicked", () => {
      this.logo.set("visible", true)
      this.logoLabel.set("visible", false)
    });

    this.emitter.on("postLoaded", (post: Post) => {
      this.emitter.emit("showLogo", true);
      this.emitter.emit("showLogoLabel", false);
      
      this.zoomToPoi(post, "postLoaded");
    });

    this.emitter.on("initZoom", () => {
      if (typeof this.logo !== 'undefined') {
        this.logo.set("visible", false)
      }

      if (typeof this.logoLabel !== 'undefined') {
        this.logoLabel.set("visible", false)
      }
    })

    this.emitter.on("ZoomToLevel2", () => {
      // this.logo.set("visible", true)
      this.emitter.emit('showBoats', true)
    });

    this.emitter.on("closedOverlay", () => {
      this.clearRouteAndPoiMark()
      this.emitter.emit('showLogoLabel', true)
    });
  }
});
