// store.js
import { reactive } from "vue";

export const store = reactive({
  showMenu: true,
  showPageOverlay: true,
  activeSubScreen: "video" as "video" | "text",
  longitude: 52.22312,
  latitude: 5.9466845,
  ReisTijd: 8,
  AnimatietijdReis:2
});
