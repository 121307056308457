import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "px-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_marquee_text = _resolveComponent("marquee-text")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createBlock(_component_b_row, {
    "no-gutters": true,
    class: "p-0 m-0",
    id: "blogTicketContainer"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_col, {
        md: 1,
        class: "p-0 m-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_button, {
            squared: "",
            id: "tickerButton",
            class: "w-100 h-100",
            to: `/${_ctx.$route.params.locale}/blog`
          }, {
            default: _withCtx(() => [
              _createTextVNode("Blog")
            ]),
            _: 1
          }, 8, ["to"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_col, {
        md: 11,
        class: "p-0 m-0"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_component_marquee_text, {
            duration: 30,
            repeat: 50,
            paused: _ctx.isPaused,
            id: "ticker",
            key: _ctx.blogs
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogs, (blog) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: blog.id,
                  onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isPaused = !_ctx.isPaused)),
                  onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPaused = false))
                }, [
                  _createVNode(_component_router_link, {
                    to: `/${_ctx.$route.params.locale}/blog/${blog.id}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(blog.attributes.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  (0 < _ctx.blogs.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
                    : _createCommentVNode("", true)
                ], 32))
              }), 128))
            ]),
            _: 1
          }, 8, ["paused"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}