
import { PropType, defineComponent } from "vue";
import { GraphType2 } from "./graph-types"
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    chartData: {
      type: Object as PropType<GraphType2>
    }
  },
  setup () {
    const { t } = useI18n();

    return {
      t
    }
  }
})
