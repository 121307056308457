
import MapMenu from "./../components/MapMenu.vue";
import { store } from "./../data/store";
import { defineComponent } from "vue";
import BlogTicker from "./../components/BlogTicker.vue";
import PagesMenu from "./../components/PagesMenu.vue";
import WorldMap from "./../components/WorldMap.vue";
import DashboardWidget from "./../components/DashboardWidget.vue";

export default defineComponent({
  inject: ['emitter'],
  data () {
    return {
      store,
      showTitle: false,
      showTitleDone: false,
      delayTime: 1000,
      visibleTime: 4000,
    };
  },
  computed: {
    isMobile (): boolean {
      return this.$isMobile;
    },
  },
  components: {
    BlogTicker,
    PagesMenu,
    WorldMap,
    DashboardWidget,
    MapMenu,
  },
  mounted () {
    // Start animation
    setTimeout(() => {
      this.emitter.emit("initZoom");
      if (this.$route.name == "Home") {
        this.showTitle = true;
      } else { 
        this.showTitle = false;
        this.showTitleDone = true;
        this.emitter.emit("ZoomToLevel2");
        this.emitter.emit("showLogo", true);
        
        if (this.$route.name != "Post") {
          this.emitter.emit("showLogoLabel", true);
        }
      }
    }, this.delayTime);

    setTimeout(() => {
      this.showTitle = false;
      this.showTitleDone = true;
      this.emitter.emit("ZoomToLevel2");
      if (this.$route.name != "Post") {
        this.emitter.emit("showLogo", true);
        this.emitter.emit("showLogoLabel", true);
      }
    }, this.visibleTime);
  },
});
