import { createRouter, createWebHistory } from "vue-router";
import {
  getLocale,
  SUPPORT_LOCALES,
  loadLocaleMessages,
  setI18nLanguage,
} from "../i18n";

import type { Router, RouteRecordRaw } from "vue-router";
import type { I18n } from "vue-i18n";

//layouts
import MainLayout from "../layout/MainLayout.vue"
import LandingLayout from "../layout/LandingLayout.vue"

export function setupRouter(i18n: I18n): Router {
  const locale = getLocale(i18n);
  
  const routes: RouteRecordRaw[] = [
    {
      path: "/:locale",
      name: "Home",
      meta: { layout: MainLayout},
      
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: () => import("@/components/DashboardWidget.vue"),
        },
        {
          path: "contact",
          name: "Contact",
          component: () => import("@/components/ContactPage.vue"),
        },
        {
          path: "blog",
          name: "BlogOverview",
          component: () => import("@/components/BlogOverviewPage.vue"),
        },
        {
          path: "contact",
          name: "Contact",
          component: () => import("@/components/ContactPage.vue"),
        },
        {
          path: "blog/:id",
          name: "Blog",
          component: () => import("@/components/BlogPage.vue"),
        },
        {
          path: "post/:id",
          name: "Post",
          component: () => import("@/components/PostPage.vue"),
        },
        {
          path: "page/:id",
          name: "Pages",
          component: () => import("@/components/PagePage.vue"),
        },
        {
          path: "about-you",
          name: "ForYou",
          component: () => import("@/components/ForYouPage.vue"),
        },
        {
          path: "waytogo",
          name: "landing",
          meta: { layout: LandingLayout},
          component: () => import("@/components/LandingPage.vue"),
          alias: 'WayToGo'
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: () => `/${locale.toLowerCase()}`,
    },
  ];

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  // navigation guards
  router.beforeEach(async (to) => {
    const paramsLocale = to.params.locale as string;

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale.toLowerCase())) {
      return `/${locale.toLowerCase()}`
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale.toLowerCase())) {
      await loadLocaleMessages(i18n, paramsLocale.toLowerCase());
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale.toLowerCase())
  });

  return router;
}
