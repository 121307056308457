export const route_usa_nl = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 2.363225313195738, 51.7793998766672 ],
      [0.6843703622939571, 50.23824066361771 ],
      [ -5.822437881741659, 49.22235161067164 ],
      [ -10.545689500107487, 43.75193375859522 ],
      [ -9.519209810132423, 36.53329836196405 ],
      [ -32.16015023483497, -4.885937266468122 ],
      [ -61.40344119874453, 12.816514684544066 ],
      [ -76.06047159729415, 14.924337812976747 ],
      [ -95.0034040627162, 28.485408480078487 ]
    ]
  }
}

export const route_new_for_demo = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 2.363225313195738, 51.7793998766672 ],
      [0.6843703622939571, 50.23824066361771 ],
      [ -5.822437881741659, 49.22235161067164 ],
      [ -10.545689500107487, 43.75193375859522 ],
      [ -9.519209810132423, 36.53329836196405 ],
      [ -32.16015023483497, -4.885937266468122 ],
      [ -61.40344119874453, 12.816514684544066 ],
      [ -76.06047159729415, 14.924337812976747 ],
      [ -95.0034040627162, 28.485408480078487 ]
    ]
  }
}

export const route_usa_nl2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 2.363225313195738, 51.7793998766672 ],
      [0.6843703622939571, 50.23824066361771 ],
      [ -5.22437881741659, 49.12235161067164 ],
      [ -11.545689500107487, 43.75193375859522 ],
      [ -9.519209810132423, 37.53329836196405 ],
      [ -33.16015023483497, -5.585937266468122 ],
      [ -64.56996378383752, 13.708047805547736 ],
      [ -75.06047159729415, 14.924337812976747 ],
      [ -95.0034040627162, 28.485408480078487 ]
    ]
  }
}

export const route_usa_sa = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [-95.0034040627162, 28.485408480078487],
      [-76.06047159729415, 14.924337812976747],
      [-61.40344119874453, 12.816514684544066],
      [ -32.16015023483497, -4.885937266468122 ],
      [-40.52176254785304, -24.032053733229738 ],
      [-44.84902810124058, -25.33527943536001],
      [18.427604258924326, -35.77628060728879]
    ]
  }
}


export const route_usa_eur = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 2.363225313195738, 51.7793998766672 ],
      [0.6843703622939571, 50.23824066361771 ],
      [ -5.822437881741659, 49.22235161067164 ],
      [-61.40344119874453, 12.816514684544066],
      [-76.06047159729415, 14.924337812976747],
      [-95.0034040627162, 28.485408480078487]
    ]
  }
}

export const route_usa_eur2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 2.363225313195738, 51.7793998766672 ],
      [0.6843703622939571, 50.23824066361771 ],
      [ -5.822437881741659, 49.32235161067164 ],
      [-63.40344119874453, 12.816514684544066],
      [-76.06047159729415, 14.224337812976747],
      [-95.0034040627162, 28.485408480078487]
    ]
  }
}


export const route_mal_dubai = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [94.10502161278174, 5.62258055374306],
      [80.56643284555241, 5.380642618153998],
      [ 77.27312900273347, 6.755092768365302],
      [65.83788671611106, 24.564034516096427],
      [56.795344663108516, 25.437060144620403]
    ]
  }
}

export const route_sa_jpn = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [-46.14948048099895, -24.700210876269384],
      [18.427604258924326, -35.77628060728879],
      [27.483928899172046, -34.66390769090755],
      [94.10502161278174, 5.62258055374306]
    ]
  }
}

export const route_map_suez = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 77.27312900273347, 6.755092768365302],
      [ 53.22094817552571, 14.117348651539396 ],
      [ 44.168214032587, 11.71888975778161],
      [42.584771981493816, 13.571373741437567],
      [ 34.45630021904598, 27.31512042357993]
    ]
  }
}

export const route_brazil_southafrica1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -33.27452089098899, -5.422259797640884 ],
      [ 18.877821227600087, -35.445316719358345 ],
    ]
  }
}

export const route_brazil_southafrica2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -33.678633375328964, -11.008479608634856 ],
      [ 18.877821227600087, -34.445316719358345 ],
    ]
  }
}

export const route_brazil_southafrica3 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -37.12779839764856, -18.08523490415678 ],
      [ 18.877821227600087, -34.445316719358345 ],
    ]
  }
}

export const route_brazil_midafrica1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -33.27452089098899, -7.02259797640884 ],
      [ -1.1223509463876966,3.872842912153241 ],
      [ 9.629262451603958, -4.323638621662981 ]
    ]
  }
}

export const route_southafrica_indonesia1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 18.877821227600087, -35.445316719358345 ],
      [ 41.37782051000354, -34.146221069749224],
      [ 95.55438842409448, 7.5393024108952895]
    ]
  }
}

export const route_southafrica_indonesia2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 19.735828349202176, -35.72202247531311 ],
      [ 42.37782051000354, -34.146221069749224],
      [ 96.55438842409448, 7.5393024108952895]
    ]
  }
}

export const route_indonesia_japan1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 96.55438842409448, 7.5393024108952895],
      [ 101.29434079513095, 2.492943281159081],
      [ 105.21324534807655, -0.2120598258215946],
      [ 119.45463469939052, 19.59565507474032 ],
      [127.53125027379313, 28.3359265000855],
      [ 138.15818587434657, 33.194908375240736 ]
    ]
  }
}

export const route_indonesia_japan2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 96.55438842409448, 7.5393024108952895],
      [ 101.29434079513095, 2.492943281159081],
      [ 105.71324534807655, -0.2120598258215946],
      [ 120.45463469939052, 19.59565507474032 ],
      [127.53125027379313, 28.3359265000855],
      [ 137.15818587434657, 33.194908375240736 ]
    ]
  }
}

export const route_china = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 119.45463469939052, 19.59565507474032 ],
      [ 119.25367639832994, 23.72222942702833],
      [123.61644086196644, 30.524276952900465 ],
      [123.50332377719462, 37.93845218767188],
      [118.07116592142191, 38.749947520917246]

    ]
  }
}


export const route_sf_argentina = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      
      [ 137.15818587434657, 33.194908375240736 ],
      [142.36003025793758, 33.537446351218705],
      [ -173.67041140382625,50.56768556638016],
      [-130.46689749852206, 51.50037201709486],
      [-124.31987113360599, 37.66332069460617],
      [-117.37789322204782, 27.509873279079578],
      [ -105.41289170912316, 17.69939631078636],
      [-81.10939981433192, 6.044579987280452],
      [-79.34322042175117, 8.4933093751572]
      
    ]
  }
}

export const route_middelandsezee = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -8.167628919217911, 35.7437049885107],
      [ -2.268238028031624, 35.96964509005916],
      [  10.973972007884482, 38.078861283689776],
      [  17.437369723529446, 34.575130801188685 ],
      
      [ 32.303729392381705, 31.499270901933365]
    ]
  }
}

export const route_westAfrika_1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 17.054893699997205, -34.704474125628224 ],
      [ 9.738109769856296, -17.47743258125926 ],
      [ 12.02922392979941, -8.631569417815696],
      [ 4.78634690804376, 2.9376917922066315 ],
      [ -12.573564083239527, 3.5722365562904574 ],
      [ -23.733507249414043, 20.906152741694065 ],
      [ -9.361291816555855, 38.58666480649013 ]
    ]
  }
}

export const route_westAfrika_2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 17.054893699997205, -34.704474125628224 ],
      [ 10.038109769856296, -17.47743258125926 ],
      [ 13.02922392979941, -8.631569417815696],
      [ 4.78634690804376, 3.376917922066315 ],
      [ -12.573564083239527, 3.0722365562904574 ],
      [ -22.733507249414043, 20.10615274169406 ],
      [ -9.361291816555855, 38.58666480649013 ]
    ]
  }
}

export const route_china_australia = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [118.07116592142191, 38.749947520917246],
      [123.50332377719462, 37.93845218767188],
      [127.55566902985221, 16.68977164591734],
      [144.98408703076117, -2.5407304665355857],
      [158.2705662065912, -18.61172399077219 ],
      [153.85223348814412, -25.09144406496437]
      

    ]
  }
}


export const route_jpn_zuidAsutralia_1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ 139.67335074329432, 34.678705236103795 ],
      [123.54867143389941, 16.45000183874173],
      [134.39749999240811, 0.7740310536966947],
      [144.98408703076117, -2.5407304665355857],
      [148.06051885430648, -6.099691666308193],
      [158.2705662065912, -18.61172399077219 ],
      [155.20445571082627, -34.46427510088729], 
      [150.0404800779828, -39.15577814380852],
      [144.887693030324, -39.421026534680735]



    ]
  }
}

export const route_zuidasia_zuidAsutralia_1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [144.887693030324, -39.421026534680735],
      [130.6329686271624, -38.45874933168083],
      [114.44175336168453, -35.4505115326641],
      [108.41711512336718, -17.71069473707757 ],
      [104.29992489578194, -6.637319123395386]
    ]
  }
}

export const route_northamerica1 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -79.3743923742956, 24.980486478884046 ],
      [ -79.6229845915902, 30.959122964399594 ],
      [ -63.215898250146275, 43.61101668436947 ],
      [ -53.21492075781645, 45.953659201907044 ],
      [ -14.630938288518573, 56.36724915037601],
      [ 25.886638411830337, 72.60819899936764 ],
      [ 42.937418943954185, 67.84377746514868 ]
    ]
  }
}

export const route_northamerica2 = {
  "geometry": {
    "type": "LineString",
    "coordinates": [
      [ -78.3743923742956, 26.980486478884046 ],
      [ -76.6229845915902, 28.959122964399594 ],
      [ -63.215898250146275, 42.61101668436947 ],
      [ -30.539142049986083, 46.136671072232076 ],
      [ -5.05086187309991, 49.327474236114 ]
    ]
  }
}

export default {
  route_usa_nl,
  route_usa_nl2,
  route_usa_sa,
  route_usa_eur,
  route_usa_eur2,
  route_mal_dubai,
  route_sa_jpn,
  route_map_suez,
  route_brazil_southafrica1,
  route_brazil_southafrica2,
  route_brazil_southafrica3,
  route_brazil_midafrica1,
  route_southafrica_indonesia1,
  route_southafrica_indonesia2,
  route_indonesia_japan1,
  route_indonesia_japan2,
  route_middelandsezee,
  route_china,
  route_china_australia,
  route_sf_argentina,
  route_westAfrika_1,
  route_westAfrika_2,
  route_jpn_zuidAsutralia_1,
  route_zuidasia_zuidAsutralia_1,
  route_northamerica1,
  route_northamerica2,
  route_new_for_demo
}

