import { gql } from "@apollo/client/core";

// PAGES
export const GET_PAGE_HEADERS = gql`
query($locale: I18NLocaleCode!) {
  pages(locale: $locale) {
    data {
      id
      attributes {
        title: Titel
        showInMenu
      }
    }
  }
  forYous(locale: $locale) {
    data {
      id
      attributes {
        title: Title
        content: Content
      }
    }
  }
}
`;
export const GET_PAGE_BY_ID = gql`
query($id: ID!, $locale: I18NLocaleCode) {
  page(id: $id, locale: $locale)
  {
    data {
      id
      attributes {
        title: Titel
        image: Afbeelding {
          data {
            attributes {
              url
            }
          }
        }
        content: Content
      }
    }
  }
}
`;

export const GET_FOR_YOU_PAGE_BY_ID = gql`
query($id: ID!, $locale: I18NLocaleCode) {
  forYou(id: $id, locale: $locale)
  {
    data {
      id
      attributes {
        title: Title
        content: Content
      }
    }
  }
}
`;

export const GET_FOR_YOU_PAGES = gql`
query($locale: I18NLocaleCode) {
  forYous(locale: $locale) {
     data {
      id
      attributes {
        title: Title
        content: Content
      }
    }
  }
}
`;

// POSTS
export const GET_POST_HEADERS = gql`
query($locale: I18NLocaleCode) {
  posts(locale: $locale)
  {
    data {
        id
        attributes {
            titel
            latitude
            longitude
            ReisTijd
            AnimatietijdReis
            positionLatitude
            positionLongitude
            Zoomlevel
            strokeDashoffset
            strokeDasharray
            Volgorde
        }
    }
  }
}
`;

export const GET_POST_BY_ID = gql`
  query ($id: ID!, $locale: I18NLocaleCode) {
    post(id: $id, locale: $locale) {
      data {
        id
        attributes {
          titel
          longitude
          latitude
          ReisTijd
          AnimatietijdReis
          vimeoURL
          content
          publishedAt
          positionLatitude
          positionLongitude
          Zoomlevel
          strokeDashoffset
          strokeDasharray
          Volgorde
        }
      }
    }
  }
`;

// BLOG
export const GET_BLOG_HEADERS = gql`
query($locale: I18NLocaleCode!) {
  blogs(locale: $locale) {
    data {
      id
      attributes {
        title: Titel
      }
    }
  }
}
`;
export const GET_BLOG_OVERVIEW = gql`
  query ($locale: I18NLocaleCode!) {
    blogs(locale: $locale) {
      data {
        id
        attributes {
          title: Titel
          content: Content
          image: Afbeelding {
            data {
              attributes {
                hash
                ext
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
          localizations {
            data {
              attributes {
                content: Content
              }
            }
          }
          locale
        }
      }
    }
  }
`;

export const GET_BLOG_BY_ID = gql`
query ($id: ID!, $locale: I18NLocaleCode!) {
  blog(id: $id, locale: $locale) {
    data {
      id
      attributes {
        title: Titel
        Content
        createdAt
        publishedAt
        Afbeelding {
          data {
            attributes {
              hash
              ext
            }
          }
        }
      }
    }
  }
}
`;