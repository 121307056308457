
import { defineComponent, onMounted, ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { GET_BLOG_HEADERS } from "../data/graphql";
import { useRoute } from "vue-router";

export default defineComponent({
  setup () {
    let route = useRoute();
    let isPaused = ref(false);
    let blogs = ref();

    function fetch (): void {
      let { onResult } = useQuery(
        GET_BLOG_HEADERS,
        { locale: route.params.locale },
        { fetchPolicy: "cache-and-network"  }
      );

      onResult((result) => {
        blogs.value = result.data.blogs.data;
      });
    }

    onMounted(() => {
      fetch();
    });

    watch(
      () => route.params.locale,
      () => {
        fetch();
      }
    );

    return {
      isPaused,
      blogs,
    };
  },
});
