import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_button_group = _resolveComponent("b-button-group")!
  const _component_b_button_toolbar = _resolveComponent("b-button-toolbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_b_button_toolbar, { id: "pagesMenu" }, {
      default: _withCtx(() => [
        _createVNode(_component_b_button_group, { size: "sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_button, {
              to: `/${_ctx.$route.params.locale}/about-you`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t ("navigations.aboutYou")), 1)
              ]),
              _: 1
            }, 8, ["to"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (Page) => {
              return (_openBlock(), _createBlock(_component_b_button, {
                key: Page.id,
                to: `/${_ctx.$route.params.locale}/page/${Page.id}`,
                title: Page.attributes.title,
                id: `page-${Page.id}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(Page.attributes.title), 1)
                ]),
                _: 2
              }, 1032, ["to", "title", "id"]))
            }), 128)),
            _createVNode(_component_b_button, {
              to: `/${_ctx.$route.params.locale}/contact`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t ("navigations.contact")), 1)
              ]),
              _: 1
            }, 8, ["to"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_b_button_toolbar, { id: "languageSwitcher" }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportLocales, (optionLocale) => {
          return (_openBlock(), _createBlock(_component_b_button, {
            key: optionLocale,
            variant: "link",
            class: _normalizeClass({ active: _ctx.$route.params.locale == optionLocale }),
            onClick: ($event: any) => (_ctx.switchLocale(optionLocale))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(optionLocale), 1)
            ]),
            _: 2
          }, 1032, ["class", "onClick"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}